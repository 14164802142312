import React from 'react';
import { Image } from "react-bootstrap";
import Banner from './components/Banner';
import Footer from './components/Footer';
import Header from './components/Header';
import Quote from './components/Quote';
import "./css/common.css";
import "./css/variables.css";
import "./css/page.css";
import headerEll from "./images/RR-headerEllipse.svg";
import LeftEllipse from "./images/RR-desktopLeftEllipse.svg";
import RightEllipse from "./images/RR-desktopRightEllipse.svg";
import BottomCircles from "./images/RR-desktopBottomCircles.svg";
import FullCircle from "./images/RR-fullCircle.svg";

const Page = () => {
    return (
        <>
            <Image
                className="desktop-banner-dot-image decor-image"
                width="30"
                height="30"
                src={headerEll}
                fluid
                alt="desktop-banner-dot image"
            />
            <Image
                className="left-ellipse-image decor-image"
                width="300"
                height="600"
                src={LeftEllipse}
                fluid
                alt="left-ellipse image"
            />
            <Image
                className="right-ellipse-image decor-image"
                width="300"
                height="600"
                src={RightEllipse}
                fluid
                alt="right-ellipse image"
            />
            <Image
                className="bottom-circles-image decor-image"
                width="100"
                height="100"
                src={BottomCircles}
                fluid
                alt="bottom-circles image"
            />
            <Image
                className="mobile-ellipse mobile-ellipse-1 decor-image"
                width="200"
                height="400"
                src={LeftEllipse}
                fluid
                alt="mobile-ellipse image"
            />
            <Image
                className="mobile-ellipse mobile-ellipse-2 decor-image"
                width="200"
                height="400"
                src={RightEllipse}
                fluid
                alt="mobile-ellipse image"
            />
            <div className='two-dots-wrapper'>
                <Image
                    className="two-dots-wrapper-image decor-image"
                    width="50"
                    height="50"
                    src={FullCircle}
                    fluid
                    alt="two-dots-wrapper image"
                />
                <Image
                    className="two-dots-wrapper-image decor-image"
                    width="15"
                    height="15"
                    src={FullCircle}
                    fluid
                    alt="two-dots-wrapper image"
                />
            </div>
            <Header />
            <Banner />
            <Quote />
            <Footer />
        </>

    );
}
 
export default Page;