import React, { useState } from 'react';
import { Slide } from 'react-awesome-reveal';
import { Container, Row, Col } from "react-bootstrap";
import "../css/quote.css";

const Quote = () => {
    
    return (
        <div className='quote-main-parent'>
            <Container>
                <Row className='quote-row'>
                    <Col lg={10}>
                        <Slide direction='up' triggerOnce>
                            <div className='quote-title poppins-semibold clr-red-shade'>
                                Work with a <span className='one-liner'>Trusted Mortgage Originator</span>
                            </div>
                        </Slide>
                        <div className='padding-wrapper'>
                            <div className='quote-symbol-wrapper'>
                                <Slide direction='up' triggerOnce delay={300}>
                                    <div className='quote-desc poppins-regular clr-blue-shade'>
                                        I have been in the mortgage business for the last 45
                                        years! I work hard to please my customers. I have
                                        experience in correspondent lending and also as a
                                        realtor and broker. I like to see future potential
                                        in my customers. I get joy when my customers’ dream
                                        of buying a home comes true.
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Quote;