import React, { useState } from 'react'
import { Image, Container, Row, Col, Button } from "react-bootstrap";
import "../css/banner.css";
import BrokerLogo from "../images/RR-BrokerLogoBordered.svg";
import Phone from "../images/Phone-red.svg";
import Mail from "../images/Mail-red.svg";
import Map from "../images/Map-red.svg";
import Meeting from "../images/meeting-icon.svg";
import ContactForm from './Form';
import { Fade, Slide } from "react-awesome-reveal";

const Banner = () => {
    const [formVisible, setFormVisible] = useState(false)

    const toggleForm = () => {
        setFormVisible(state => !state)
    }
    
    return <>
        <div className='banner'>
            <div className="banner-image">

            </div>
            <Container className='section-container'>
                <Row>
                    <Col xl={8} lg={9} md={12} sm={12} xs={12} className='banner-left-content'>
                        <Fade fraction={0} triggerOnce>
                            <Row className='left-content-title'>
                                <div className='poppins-bold uppercase clr-blue-shade'> Realize Your Dream of  </div>
                                <div className='lucky-fellas clr-red-shade'>
                                    Homeownership
                                </div>
                            </Row>
                        </Fade>
                        <Row className='left-content-details'>
                            <Col xl={3} lg={3} md={3} sm={12} className='details-image'>
                                <div className='broker-image-wrapper'>
                                    <Image
                                        className="broker-image"
                                        width="250"
                                        height="250"
                                        src={BrokerLogo}
                                        fluid
                                        alt="rosie image"
                                    />
                                </div>
                            </Col>
                            <Col lg={8} md={8} className='details-desc'>
                                <Slide direction='up' duration={500} damping={0.1} triggerOnce>
                                    <div className='name poppins-semibold clr-red-shade'>Rosie Rosario</div>
                                </Slide>
                                <div className='work poppins-regular clr-blue-shade'>
                                    <Slide direction='up' duration={500} damping={0.1} triggerOnce delay={300}>
                                        <div>Mortgage Loan Originator</div>
                                    </Slide>
                                    <Slide direction='up' duration={500} damping={0.1} triggerOnce delay={400} >

                                        <div>NMLS 366306</div>
                                        <div className='tel-div'>
                                            <a
                                                className='telephone clr-blue-shade'
                                                href="tel:+19542149470"
                                            >
                                                <Row>
                                                    <Col className='img-col' lg={1} md={1} sm={1}>
                                                        <Image
                                                            className="phone-image"
                                                            width="20"
                                                            height="20"
                                                            src={Phone}
                                                            fluid
                                                            alt="phone image"
                                                        />
                                                    </Col>
                                                    <Col className='text-col' lg={5} md={4} sm={5} xs={7}>

                                                        (954) 214-9470
                                                    </Col>
                                                </Row>
                                            </a>
                                        </div>
                                        <div className='mail-div'>
                                            <a
                                                className='mail clr-blue-shade'
                                                href="mailto:Rosariomortgage@hotmail.com"
                                            >
                                                <Row>
                                                    <Col className='img-col' lg={1} md={1} sm={1}>
                                                        <Image
                                                            className="mail-image"
                                                            width="20"
                                                            height="20"
                                                            src={Mail}
                                                            fluid
                                                            alt="phone image"
                                                        />
                                                    </Col>
                                                    <Col className='text-col' lg={10} md={8} xs={11}>
                                                        Rosariomortgage@hotmail.com
                                                    </Col>
                                                </Row>
                                            </a>
                                        </div>
                                        <div className='address-wrapper'>
                                            <a
                                                href='https://www.google.com/maps/place/3440+Hollywood+Blvd,+Hollywood,+FL+33021/@26.0102008,-80.1794509,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9abe6316cc671:0xf832cd76bd436202!8m2!3d26.010196!4d-80.1772622'
                                                target='_blank'
                                                className='clr-blue-shade address'
                                            >
                                                <Row>
                                                    <Col className='img-col' lg={1} md={1} sm={1}>
                                                        <Image
                                                            className="map-image"
                                                            width="50"
                                                            height="20"
                                                            src={Map}
                                                            fluid
                                                            alt="map image"
                                                        />
                                                    </Col>
                                                    <Col className='text-col'>
                                                        <div>
                                                            3440 Hollywood Blvd Suite 415,<br />
                                                            Hollywood, FL 33021<br />
                                                            Branch NMLS 1108258
                                                        </div>                                                        
                                                    </Col>
                                                </Row>
                                            </a>
                                        </div>                                        
                                    </Slide>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={10} className="pt-4 text-center">
                                <Button className='meeting-btn poppins-regular' onClick={toggleForm}>
                                    <Row className='meeting-btn-row'>
                                        <Col className='column' xl={3} lg={1} md={1} sm={1} xs={2} >
                                            <Image
                                                className="meeting-image"
                                                width="50"
                                                height="50"
                                                src={Meeting}
                                                fluid
                                                alt="meeting image"
                                            />
                                        </Col>
                                        <Col className='column'>
                                            <div>
                                                Schedule a Meeting or<br />Call back
                                            </div>
                                        </Col>
                                    </Row>
                                </Button>
                                {
                                    formVisible &&
                                        <ContactForm />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
}

export default Banner