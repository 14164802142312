import React from 'react';
import { Image, Container, Row, Col } from "react-bootstrap";
import EHO from "../images/RR-EHO.svg";
import "../css/footer.css";

const Footer = () => {
    return (
        <div className='footer'>
            <Container>
                <Row className='footer-row'>
                    <Col lg={2} md={3} className="eho-col">
                        <Image
                            className="eho-logo"
                            width="100"
                            height="100"
                            src={EHO}
                            fluid
                            alt="EHO logo"
                        />
                    </Col>
                    <Col className='footer-para'>
                        <div className='footer-content poppins-regular'>
                            For Florida residents only. Rosie Rosario is licensed in FL. The content here does
                            not substitute for professional legal, securities, tax or accounting advice. US Mortgage Lenders (NMLS ID # 1108258,
                            {' '}<a className="clr-black-shade" href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277" target="_blank" without rel="noreferrer" title="NMLS Consumer Access">www.nmlsconsumeraccess.org</a>)
                            in Florida holds a Mortgage Broker License (#MBR1458), licensed by the
                            Florida Office of Financial Regulation, Phone: <a className='clr-black-shade' href="tel:+18504109895" >(850) 410-9895</a>. For licensing information, go to:
                            {' '}<a className="clr-black-shade" href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277" target="_blank" without rel="noreferrer" title="NMLS Consumer Access">www.nmlsconsumeraccess.org</a>.
                            In all jurisdictions, the principal (Main) licensed location of Sun West
                            Mortgage Company, Inc. is{' '}
                            <a
                                href='https://www.google.com/maps/place/3440+Hollywood+Blvd,+Hollywood,+FL+33021/@26.0102008,-80.1794509,17z/data=!3m1!4b1!4m5!3m4!1s0x88d9abe6316cc671:0xf832cd76bd436202!8m2!3d26.010196!4d-80.1772622'
                                target='_blank'
                                className='clr-black-shade'
                            >3440 Hollywood Blvd, Suite 415, Hollywood, FL 33021</a>
                            ,{' '}
                            Phone: <a className='clr-black-shade one-liner' href="tel:+19546674110" >(954) 667-4110</a>.
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='footer-bottom'>
            </div>
        </div>
    );
}

export default Footer;